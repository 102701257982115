import { generatePath } from 'react-router-dom';
var agencies = 'agencies';
var agencyId = ':agencyId';
var serviceFeeId = ':serviceFeeId';
var commercialInfo = 'commercial-info';
var creditInfo = 'credit-info';
var balanceDetails = 'balance-details';
var commissions = 'agency-commissions';
var create = 'create';
var edit = 'edit';
var info = 'info';
var search = 'search';
var serviceFee = 'service-fee';
var userId = ':userId';
var feeId = ':feeId';
var commissionId = ':commissionId';
var airlineCommissionId = ':airlineCommissionId';
var users = 'users';
var addFee = 'add-fee';
var accessDenied = 'access-denied';
var airlinesCommissions = 'airlines-commissions';
var rules = 'rules';
var sort = 'sort';
var ruleId = ':ruleId';
var duplicate = 'duplicate';
export var routes = {
    agencies: agencies,
    agencyId: agencyId,
    serviceFeeId: serviceFeeId,
    commercialInfo: commercialInfo,
    creditInfo: creditInfo,
    commissions: commissions,
    create: create,
    edit: edit,
    balanceDetails: balanceDetails,
    airlinesCommissions: airlinesCommissions,
    airlineCommissionId: airlineCommissionId,
    goToAgencies: "/".concat(agencies),
    goToAirlineCommissions: "/".concat(airlinesCommissions),
    rules: rules,
    ruleId: ruleId,
    sort: sort,
    addRule: "".concat(rules, "/").concat(create),
    duplicate: duplicate,
    gotoRule: function (id) {
        return generatePath("".concat(rules, "/").concat(ruleId), { ruleId: String(id) });
    },
    goToAgency: function (id) {
        return generatePath("/".concat(agencies, "/:id"), { id: String(id) });
    },
    goToAgencyEditInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(info), { id: String(id) });
    },
    goToCommissions: "/".concat(commissions),
    goToCommissionModule: function (commissionId) {
        return generatePath("/".concat(commissions, "/:commissionId"), {
            commissionId: String(commissionId),
        });
    },
    goToCreateAgency: "/".concat(agencies, "/").concat(create),
    goToAgencyEditServiceFee: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(serviceFee), { id: String(id) });
    },
    goToServiceFee: "/".concat(serviceFee),
    goToServiceFeeModule: function (feeId) {
        return generatePath("/".concat(serviceFee, "/:feeId"), { feeId: String(feeId) });
    },
    goToAgencyEditCommercialInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(commercialInfo), {
            id: String(id),
        });
    },
    goToAgencyEditCreditInfo: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(creditInfo), {
            id: String(id),
        });
    },
    goToAgencyBalanceDetails: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(balanceDetails), {
            id: String(id),
        });
    },
    goToAgencyUsers: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(users), { id: String(id) });
    },
    goToAgencyUser: function (agencyId, userId) {
        return generatePath("/".concat(agencies, "/:agencyId/").concat(users, "/:userId"), {
            agencyId: String(agencyId),
            userId: String(userId),
        });
    },
    goToAgencyCreateUser: function (id) {
        return generatePath("/".concat(agencies, "/:id/").concat(users, "/").concat(create), {
            id: String(id),
        });
    },
    goToAgencyEditUser: function (agencyId, userId) {
        return generatePath("/".concat(agencies, "/:agencyId/").concat(users, "/:userId/").concat(edit), {
            agencyId: String(agencyId),
            userId: String(userId),
        });
    },
    goToConsolidFeeModules: function () { return "/".concat(serviceFee); },
    info: info,
    search: search,
    serviceFee: serviceFee,
    users: users,
    userId: userId,
    feeId: feeId,
    commissionId: commissionId,
    addFee: addFee,
    accessDenied: accessDenied,
};
