import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { trackers } from '@/configurations/trackers';
import { useAuth } from '@agentguru/auth';
import { useTranslation } from '@agentguru/i18n';
import { AppAgencyRoute } from '../components/AppAgencyRoute';
import { BottomNavBarUserProfile } from '../components/BottomNavBarUserProfile';
import { CockpitRoute } from '../components/CockpitRoute';
import { routes } from '../constants';
export function useBottomNavBarRoutes() {
    var t = useTranslation().t;
    var logout = useAuth().logout;
    var handleLogout = useCallback(function () {
        logout();
        trackers.destroy();
    }, [logout]);
    return useMemo(function () {
        return [
            {
                key: 0,
                icon: 'ag-agency',
                disabled: false,
                path: routes.goToAgencies,
                matches: [routes.agencies],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 1,
                icon: 'ag-commission',
                disabled: false,
                path: routes.goToCommissions,
                matches: [routes.commissions],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 2,
                icon: 'ag-payment',
                disabled: false,
                path: routes.goToServiceFee,
                matches: [routes.serviceFee],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 4,
                icon: 'ag-airline-commissions',
                disabled: false,
                path: routes.goToAirlineCommissions,
                matches: [routes.airlinesCommissions],
                isCustomRoute: false,
                subRoutes: [],
            },
            {
                key: 5,
                icon: 'ag-profile',
                disabled: false,
                path: '/',
                matches: [],
                isCustomRoute: true,
                subRoutes: [
                    {
                        key: 0,
                        icon: '',
                        disabled: false,
                        path: '/',
                        label: 'BottomNavBarUserProfile',
                        customRender: _jsx(BottomNavBarUserProfile, {}),
                    },
                    // {
                    //   key: 1,
                    //   icon: '',
                    //   disabled: false,
                    //   path: '/',
                    //   label: 'BottomNavBarWhatsNew',
                    //   customRender: <BottomNavBarWhatsNew />,
                    // },
                    {
                        key: 2,
                        icon: '',
                        disabled: false,
                        path: '/',
                        label: 'AppAgencyRoute',
                        customRender: _jsx(AppAgencyRoute, {}),
                    },
                    {
                        key: 3,
                        icon: '',
                        disabled: false,
                        path: '/',
                        label: 'CockpitRoute',
                        customRender: _jsx(CockpitRoute, {}),
                    },
                    {
                        key: 4,
                        icon: 'ag-logout',
                        disabled: false,
                        path: '/',
                        label: t('routes.logout'),
                        callback: function () { return handleLogout(); },
                    },
                ],
            },
        ];
    }, [handleLogout, t]);
}
