export var ServiceErrors;
(function (ServiceErrors) {
    ServiceErrors["GENERAL_ERROR"] = "GENERAL_ERROR";
    ServiceErrors["PNR_NOT_FOUND"] = "PNR_NOT_FOUND";
    ServiceErrors["SECURED_PNR"] = "SECURED_PNR";
    ServiceErrors["BOOKING_ALREADY_IMPORTED"] = "BOOKING_ALREADY_IMPORTED";
    ServiceErrors["PARTIAL_UPDATE"] = "PARTIAL_UPDATE";
})(ServiceErrors || (ServiceErrors = {}));
export var ErrorExtensionCode;
(function (ErrorExtensionCode) {
    ErrorExtensionCode["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
})(ErrorExtensionCode || (ErrorExtensionCode = {}));
