import { array, number, object, string } from 'yup';
import { ErrorExtensionCode, ServiceErrors } from '@/graphql';
var schema = object({
    code: string().required(),
    exception: object({
        code: number().required(),
        message: string().required(),
        details: array()
            .of(object({
            '@type': string().required(),
            reason: string().required(),
            domain: string().required(),
            metadata: object({
                service: string().nullable(),
                providerMessage: string().required(),
            }).required(),
        }))
            .required(),
    }),
});
export function parseErrorToServiceError(error) {
    var _a, _b, _c;
    try {
        var payloadError = schema.validateSync((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.extensions);
        return payloadError;
    }
    catch (error) {
        var errorExpect = {
            code: ErrorExtensionCode.INTERNAL_SERVER_ERROR,
            exception: {
                code: 0,
                message: ServiceErrors.GENERAL_ERROR,
                details: [
                    {
                        '@type': 'mockedError',
                        reason: ServiceErrors.GENERAL_ERROR,
                        domain: 'agent.guru',
                        metadata: {},
                    },
                ],
            },
        };
        return errorExpect;
    }
}
