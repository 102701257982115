import { useMutation, useQuery, } from '@tanstack/react-query';
import { fetcherWithGraphQLClient } from '@/configurations/reactQuery/fetcher';
/** The agency activity Type UNSPECIFIED|CREATED|EDITED|DELETED|ENABLED|DISABLED|CONSOLID_FEE_MODULE_EDITED|COMMISSION_MODULE_EDITED */
export var AgencyActivityType;
(function (AgencyActivityType) {
    AgencyActivityType["AgencyActivityTypeCommissionModuleEdited"] = "AGENCY_ACTIVITY_TYPE_COMMISSION_MODULE_EDITED";
    AgencyActivityType["AgencyActivityTypeConsolidFeeModuleEdited"] = "AGENCY_ACTIVITY_TYPE_CONSOLID_FEE_MODULE_EDITED";
    AgencyActivityType["AgencyActivityTypeCreated"] = "AGENCY_ACTIVITY_TYPE_CREATED";
    AgencyActivityType["AgencyActivityTypeCreditEdited"] = "AGENCY_ACTIVITY_TYPE_CREDIT_EDITED";
    AgencyActivityType["AgencyActivityTypeDeleted"] = "AGENCY_ACTIVITY_TYPE_DELETED";
    AgencyActivityType["AgencyActivityTypeDisabled"] = "AGENCY_ACTIVITY_TYPE_DISABLED";
    AgencyActivityType["AgencyActivityTypeEdited"] = "AGENCY_ACTIVITY_TYPE_EDITED";
    AgencyActivityType["AgencyActivityTypeEnabled"] = "AGENCY_ACTIVITY_TYPE_ENABLED";
    AgencyActivityType["AgencyActivityTypeUnspecified"] = "AGENCY_ACTIVITY_TYPE_UNSPECIFIED";
})(AgencyActivityType || (AgencyActivityType = {}));
/** The agency consolid fee module activity Type UNSPECIFIED|CREATED|EDITED|DELETED */
export var AgencyConsolidFeeModuleActivityType;
(function (AgencyConsolidFeeModuleActivityType) {
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeCreated"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_CREATED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeDeleted"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_DELETED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeEdited"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_EDITED";
    AgencyConsolidFeeModuleActivityType["AgencyConsolidFeeModuleActivityTypeUnspecified"] = "AGENCY_CONSOLID_FEE_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(AgencyConsolidFeeModuleActivityType || (AgencyConsolidFeeModuleActivityType = {}));
/** The Airline Rule Activity Type */
export var AirlineRuleActivityType;
(function (AirlineRuleActivityType) {
    AirlineRuleActivityType["AirlineRuleActivityTypeCreated"] = "AIRLINE_RULE_ACTIVITY_TYPE_CREATED";
    AirlineRuleActivityType["AirlineRuleActivityTypeDeleted"] = "AIRLINE_RULE_ACTIVITY_TYPE_DELETED";
    AirlineRuleActivityType["AirlineRuleActivityTypeDisabled"] = "AIRLINE_RULE_ACTIVITY_TYPE_DISABLED";
    AirlineRuleActivityType["AirlineRuleActivityTypeEdited"] = "AIRLINE_RULE_ACTIVITY_TYPE_EDITED";
    AirlineRuleActivityType["AirlineRuleActivityTypeEnabled"] = "AIRLINE_RULE_ACTIVITY_TYPE_ENABLED";
    AirlineRuleActivityType["AirlineRuleActivityTypeUnspecified"] = "AIRLINE_RULE_ACTIVITY_TYPE_UNSPECIFIED";
})(AirlineRuleActivityType || (AirlineRuleActivityType = {}));
/** The Airline Rule Condition Operator Type */
export var AirlineRuleConditionOperatorType;
(function (AirlineRuleConditionOperatorType) {
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeAfter"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_AFTER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeBefore"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_BEFORE";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeBetween"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_BETWEEN";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeEqual"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_EQUAL";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeGrater"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_GRATER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeLesser"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_LESSER";
    AirlineRuleConditionOperatorType["AirlineRuleConditionOperatorTypeNotEqual"] = "AIRLINE_RULE_CONDITION_OPERATOR_TYPE_NOT_EQUAL";
})(AirlineRuleConditionOperatorType || (AirlineRuleConditionOperatorType = {}));
/** The Airline Rule Condition Type */
export var AirlineRuleConditionType;
(function (AirlineRuleConditionType) {
    AirlineRuleConditionType["AirlineRuleConditionTypeAccountCode"] = "AIRLINE_RULE_CONDITION_TYPE_ACCOUNT_CODE";
    AirlineRuleConditionType["AirlineRuleConditionTypeCabin"] = "AIRLINE_RULE_CONDITION_TYPE_CABIN";
    AirlineRuleConditionType["AirlineRuleConditionTypeClassOfService"] = "AIRLINE_RULE_CONDITION_TYPE_CLASS_OF_SERVICE";
    AirlineRuleConditionType["AirlineRuleConditionTypeCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeDepartureDate"] = "AIRLINE_RULE_CONDITION_TYPE_DEPARTURE_DATE";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationAirport"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_AIRPORT";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationCity"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_CITY";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationContinent"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_CONTINENT";
    AirlineRuleConditionType["AirlineRuleConditionTypeDestinationCountry"] = "AIRLINE_RULE_CONDITION_TYPE_DESTINATION_COUNTRY";
    AirlineRuleConditionType["AirlineRuleConditionTypeDomestic"] = "AIRLINE_RULE_CONDITION_TYPE_DOMESTIC";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareBasisClassOfService"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_BASIS_CLASS_OF_SERVICE";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareFamily"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_FAMILY";
    AirlineRuleConditionType["AirlineRuleConditionTypeFareType"] = "AIRLINE_RULE_CONDITION_TYPE_FARE_TYPE";
    AirlineRuleConditionType["AirlineRuleConditionTypeFlightNumber"] = "AIRLINE_RULE_CONDITION_TYPE_FLIGHT_NUMBER";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasCodeshare"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_CODESHARE";
    AirlineRuleConditionType["AirlineRuleConditionTypeHasInterline"] = "AIRLINE_RULE_CONDITION_TYPE_HAS_INTERLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeInterline"] = "AIRLINE_RULE_CONDITION_TYPE_INTERLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeInternationalOnline"] = "AIRLINE_RULE_CONDITION_TYPE_INTERNATIONAL_ONLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeOnline"] = "AIRLINE_RULE_CONDITION_TYPE_ONLINE";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginAirport"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_AIRPORT";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginCity"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_CITY";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginContinent"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_CONTINENT";
    AirlineRuleConditionType["AirlineRuleConditionTypeOriginCountry"] = "AIRLINE_RULE_CONDITION_TYPE_ORIGIN_COUNTRY";
    AirlineRuleConditionType["AirlineRuleConditionTypeReturnDate"] = "AIRLINE_RULE_CONDITION_TYPE_RETURN_DATE";
    AirlineRuleConditionType["AirlineRuleConditionTypeSaleDate"] = "AIRLINE_RULE_CONDITION_TYPE_SALE_DATE";
})(AirlineRuleConditionType || (AirlineRuleConditionType = {}));
/** The Airline Rule Condition Value Type */
export var AirlineRuleConditionValueType;
(function (AirlineRuleConditionValueType) {
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeBoolean"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_BOOLEAN";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeCabinCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_CABIN_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDate"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateTime"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_TIME";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeDateTimeRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_DATE_TIME_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeFareTypeCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_FARE_TYPE_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataAirlineCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_AIRLINE_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataAirportCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_AIRPORT_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataCityCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_CITY_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataContinentCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_CONTINENT_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIataCountryCode"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_IATA_COUNTRY_CODE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeInt"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_INT";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeIntRange"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_INT_RANGE";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeOneLetter"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_ONE_LETTER";
    AirlineRuleConditionValueType["AirlineRuleConditionValueTypeString"] = "AIRLINE_RULE_CONDITION_VALUE_TYPE_STRING";
})(AirlineRuleConditionValueType || (AirlineRuleConditionValueType = {}));
/** The Airline Rules Setting Activity Type */
export var AirlineRulesSettingActivityType;
(function (AirlineRulesSettingActivityType) {
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeCreated"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_CREATED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeDeleted"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_DELETED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeDisabled"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_DISABLED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeEdited"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_EDITED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeEnabled"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_ENABLED";
    AirlineRulesSettingActivityType["AirlineRulesSettingActivityTypeUnspecified"] = "AIRLINE_RULES_SETTING_ACTIVITY_TYPE_UNSPECIFIED";
})(AirlineRulesSettingActivityType || (AirlineRulesSettingActivityType = {}));
/** Baggage */
export var Baggage;
(function (Baggage) {
    Baggage["Baggage_1Piece"] = "BAGGAGE_1_PIECE";
    Baggage["Baggage_1Piece_23Kg"] = "BAGGAGE_1_PIECE_23KG";
    Baggage["Baggage_1Piece_32Kg"] = "BAGGAGE_1_PIECE_32KG";
    Baggage["Baggage_2Piece"] = "BAGGAGE_2_PIECE";
    Baggage["Baggage_2Piece_23Kg"] = "BAGGAGE_2_PIECE_23KG";
    Baggage["Baggage_2Piece_32Kg"] = "BAGGAGE_2_PIECE_32KG";
    Baggage["Baggage_3Piece"] = "BAGGAGE_3_PIECE";
    Baggage["Baggage_3Piece_23Kg"] = "BAGGAGE_3_PIECE_23KG";
    Baggage["Baggage_3Piece_32Kg"] = "BAGGAGE_3_PIECE_32KG";
    Baggage["BaggageNo"] = "BAGGAGE_NO";
    Baggage["BaggageUnspecified"] = "BAGGAGE_UNSPECIFIED";
})(Baggage || (Baggage = {}));
/** The contact type AGENT|AGENCY */
export var BookingContactType;
(function (BookingContactType) {
    BookingContactType["BookingContactTypeAgency"] = "BOOKING_CONTACT_TYPE_AGENCY";
    BookingContactType["BookingContactTypeAgent"] = "BOOKING_CONTACT_TYPE_AGENT";
    BookingContactType["BookingContactTypeUnspecified"] = "BOOKING_CONTACT_TYPE_UNSPECIFIED";
})(BookingContactType || (BookingContactType = {}));
/** Cabin */
export var Cabin;
(function (Cabin) {
    Cabin["CabinBusiness"] = "CABIN_BUSINESS";
    Cabin["CabinEconomy"] = "CABIN_ECONOMY";
    Cabin["CabinFirst"] = "CABIN_FIRST";
    Cabin["CabinPremium"] = "CABIN_PREMIUM";
    Cabin["CabinUnspecified"] = "CABIN_UNSPECIFIED";
})(Cabin || (Cabin = {}));
/** The card brand */
export var CardBrand;
(function (CardBrand) {
    CardBrand["CardBrandAmex"] = "CARD_BRAND_AMEX";
    CardBrand["CardBrandDiners"] = "CARD_BRAND_DINERS";
    CardBrand["CardBrandDiscover"] = "CARD_BRAND_DISCOVER";
    CardBrand["CardBrandMastercard"] = "CARD_BRAND_MASTERCARD";
    CardBrand["CardBrandUnspecified"] = "CARD_BRAND_UNSPECIFIED";
    CardBrand["CardBrandVisa"] = "CARD_BRAND_VISA";
})(CardBrand || (CardBrand = {}));
/** The commission module activity Type UNSPECIFIED|CREATED|EDITED|DELETED */
export var CommissionModuleActivityType;
(function (CommissionModuleActivityType) {
    CommissionModuleActivityType["CommissionModuleActivityTypeCreated"] = "COMMISSION_MODULE_ACTIVITY_TYPE_CREATED";
    CommissionModuleActivityType["CommissionModuleActivityTypeDeleted"] = "COMMISSION_MODULE_ACTIVITY_TYPE_DELETED";
    CommissionModuleActivityType["CommissionModuleActivityTypeEdited"] = "COMMISSION_MODULE_ACTIVITY_TYPE_EDITED";
    CommissionModuleActivityType["CommissionModuleActivityTypeUnspecified"] = "COMMISSION_MODULE_ACTIVITY_TYPE_UNSPECIFIED";
})(CommissionModuleActivityType || (CommissionModuleActivityType = {}));
/** The credit period type WEEK|MONTH */
export var CreditPeriodType;
(function (CreditPeriodType) {
    CreditPeriodType["CreditPeriodTypeMonth"] = "CREDIT_PERIOD_TYPE_MONTH";
    CreditPeriodType["CreditPeriodTypeUnspecified"] = "CREDIT_PERIOD_TYPE_UNSPECIFIED";
    CreditPeriodType["CreditPeriodTypeWeek"] = "CREDIT_PERIOD_TYPE_WEEK";
})(CreditPeriodType || (CreditPeriodType = {}));
/** The credit status type HAS_CREDIT|NO_CREDIT|NO_CREDIT_AND_BLOCKED */
export var CreditStatusType;
(function (CreditStatusType) {
    CreditStatusType["CreditStatusTypeHasCredit"] = "CREDIT_STATUS_TYPE_HAS_CREDIT";
    CreditStatusType["CreditStatusTypeNoCredit"] = "CREDIT_STATUS_TYPE_NO_CREDIT";
    CreditStatusType["CreditStatusTypeNoCreditAndBlocked"] = "CREDIT_STATUS_TYPE_NO_CREDIT_AND_BLOCKED";
    CreditStatusType["CreditStatusTypeUnspecified"] = "CREDIT_STATUS_TYPE_UNSPECIFIED";
})(CreditStatusType || (CreditStatusType = {}));
/** The form of payments */
export var FopType;
(function (FopType) {
    FopType["FopTypeCreditcard"] = "FOP_TYPE_CREDITCARD";
    FopType["FopTypeInvoice"] = "FOP_TYPE_INVOICE";
    FopType["FopTypeUnspecified"] = "FOP_TYPE_UNSPECIFIED";
})(FopType || (FopType = {}));
/** The gender type */
export var GenderType;
(function (GenderType) {
    GenderType["GenderTypeFemale"] = "GENDER_TYPE_FEMALE";
    GenderType["GenderTypeMale"] = "GENDER_TYPE_MALE";
    GenderType["GenderTypeUnspecified"] = "GENDER_TYPE_UNSPECIFIED";
})(GenderType || (GenderType = {}));
/** The leg status */
export var LegStatus;
(function (LegStatus) {
    LegStatus["LegStatusCanceled"] = "LEG_STATUS_CANCELED";
    LegStatus["LegStatusCheckedIn"] = "LEG_STATUS_CHECKED_IN";
    LegStatus["LegStatusConfirmed"] = "LEG_STATUS_CONFIRMED";
    LegStatus["LegStatusFlown"] = "LEG_STATUS_FLOWN";
    LegStatus["LegStatusFraud"] = "LEG_STATUS_FRAUD";
    LegStatus["LegStatusNoShow"] = "LEG_STATUS_NO_SHOW";
    LegStatus["LegStatusPendingConfirmation"] = "LEG_STATUS_PENDING_CONFIRMATION";
    LegStatus["LegStatusRefunded"] = "LEG_STATUS_REFUNDED";
    LegStatus["LegStatusReissued"] = "LEG_STATUS_REISSUED";
    LegStatus["LegStatusScheduleChange"] = "LEG_STATUS_SCHEDULE_CHANGE";
    LegStatus["LegStatusSuspended"] = "LEG_STATUS_SUSPENDED";
    LegStatus["LegStatusUnspecified"] = "LEG_STATUS_UNSPECIFIED";
    LegStatus["LegStatusVoid"] = "LEG_STATUS_VOID";
    LegStatus["LegStatusWaitList"] = "LEG_STATUS_WAIT_LIST";
})(LegStatus || (LegStatus = {}));
/** PaxType */
export var PaxType;
(function (PaxType) {
    PaxType["PaxTypeAdt"] = "PAX_TYPE_ADT";
    PaxType["PaxTypeChd"] = "PAX_TYPE_CHD";
    PaxType["PaxTypeInf"] = "PAX_TYPE_INF";
    PaxType["PaxTypeUnspecified"] = "PAX_TYPE_UNSPECIFIED";
})(PaxType || (PaxType = {}));
/** The role permission */
export var RolePermission;
(function (RolePermission) {
    RolePermission["RolePermissionCreateAgency"] = "ROLE_PERMISSION_CREATE_AGENCY";
    RolePermission["RolePermissionCreateAirline"] = "ROLE_PERMISSION_CREATE_AIRLINE";
    RolePermission["RolePermissionCreateAirlineRule"] = "ROLE_PERMISSION_CREATE_AIRLINE_RULE";
    RolePermission["RolePermissionCreateAny"] = "ROLE_PERMISSION_CREATE_ANY";
    RolePermission["RolePermissionCreateAvatarAgency"] = "ROLE_PERMISSION_CREATE_AVATAR_AGENCY";
    RolePermission["RolePermissionCreateAvatarAny"] = "ROLE_PERMISSION_CREATE_AVATAR_ANY";
    RolePermission["RolePermissionCreateBookingAgency"] = "ROLE_PERMISSION_CREATE_BOOKING_AGENCY";
    RolePermission["RolePermissionCreateBookingAny"] = "ROLE_PERMISSION_CREATE_BOOKING_ANY";
    RolePermission["RolePermissionCreateLocation"] = "ROLE_PERMISSION_CREATE_LOCATION";
    RolePermission["RolePermissionCreateOffer"] = "ROLE_PERMISSION_CREATE_OFFER";
    RolePermission["RolePermissionCreateOrderAgency"] = "ROLE_PERMISSION_CREATE_ORDER_AGENCY";
    RolePermission["RolePermissionCreateOrderAny"] = "ROLE_PERMISSION_CREATE_ORDER_ANY";
    RolePermission["RolePermissionCreateTicketAgency"] = "ROLE_PERMISSION_CREATE_TICKET_AGENCY";
    RolePermission["RolePermissionCreateTicketAny"] = "ROLE_PERMISSION_CREATE_TICKET_ANY";
    RolePermission["RolePermissionCreateUserAgency"] = "ROLE_PERMISSION_CREATE_USER_AGENCY";
    RolePermission["RolePermissionCreateUserAny"] = "ROLE_PERMISSION_CREATE_USER_ANY";
    RolePermission["RolePermissionDeleteAgency"] = "ROLE_PERMISSION_DELETE_AGENCY";
    RolePermission["RolePermissionDeleteAirline"] = "ROLE_PERMISSION_DELETE_AIRLINE";
    RolePermission["RolePermissionDeleteAirlineRule"] = "ROLE_PERMISSION_DELETE_AIRLINE_RULE";
    RolePermission["RolePermissionDeleteAny"] = "ROLE_PERMISSION_DELETE_ANY";
    RolePermission["RolePermissionDeleteAvatarAgency"] = "ROLE_PERMISSION_DELETE_AVATAR_AGENCY";
    RolePermission["RolePermissionDeleteAvatarAny"] = "ROLE_PERMISSION_DELETE_AVATAR_ANY";
    RolePermission["RolePermissionDeleteAvatarOwn"] = "ROLE_PERMISSION_DELETE_AVATAR_OWN";
    RolePermission["RolePermissionDeleteBookingAgency"] = "ROLE_PERMISSION_DELETE_BOOKING_AGENCY";
    RolePermission["RolePermissionDeleteBookingAny"] = "ROLE_PERMISSION_DELETE_BOOKING_ANY";
    RolePermission["RolePermissionDeleteBookingOwn"] = "ROLE_PERMISSION_DELETE_BOOKING_OWN";
    RolePermission["RolePermissionDeleteLocation"] = "ROLE_PERMISSION_DELETE_LOCATION";
    RolePermission["RolePermissionDeleteOffer"] = "ROLE_PERMISSION_DELETE_OFFER";
    RolePermission["RolePermissionDeleteOrderAgency"] = "ROLE_PERMISSION_DELETE_ORDER_AGENCY";
    RolePermission["RolePermissionDeleteOrderAny"] = "ROLE_PERMISSION_DELETE_ORDER_ANY";
    RolePermission["RolePermissionDeleteOrderOwn"] = "ROLE_PERMISSION_DELETE_ORDER_OWN";
    RolePermission["RolePermissionDeleteTicketAgency"] = "ROLE_PERMISSION_DELETE_TICKET_AGENCY";
    RolePermission["RolePermissionDeleteTicketAny"] = "ROLE_PERMISSION_DELETE_TICKET_ANY";
    RolePermission["RolePermissionDeleteTicketOwn"] = "ROLE_PERMISSION_DELETE_TICKET_OWN";
    RolePermission["RolePermissionDeleteUserAgency"] = "ROLE_PERMISSION_DELETE_USER_AGENCY";
    RolePermission["RolePermissionDeleteUserAny"] = "ROLE_PERMISSION_DELETE_USER_ANY";
    RolePermission["RolePermissionDeleteUserOwn"] = "ROLE_PERMISSION_DELETE_USER_OWN";
    RolePermission["RolePermissionReadAgency"] = "ROLE_PERMISSION_READ_AGENCY";
    RolePermission["RolePermissionReadAgencySettings"] = "ROLE_PERMISSION_READ_AGENCY_SETTINGS";
    RolePermission["RolePermissionReadAirline"] = "ROLE_PERMISSION_READ_AIRLINE";
    RolePermission["RolePermissionReadAirlineRule"] = "ROLE_PERMISSION_READ_AIRLINE_RULE";
    RolePermission["RolePermissionReadAny"] = "ROLE_PERMISSION_READ_ANY";
    RolePermission["RolePermissionReadAvatarAgency"] = "ROLE_PERMISSION_READ_AVATAR_AGENCY";
    RolePermission["RolePermissionReadAvatarAny"] = "ROLE_PERMISSION_READ_AVATAR_ANY";
    RolePermission["RolePermissionReadAvatarOwn"] = "ROLE_PERMISSION_READ_AVATAR_OWN";
    RolePermission["RolePermissionReadBookingAgency"] = "ROLE_PERMISSION_READ_BOOKING_AGENCY";
    RolePermission["RolePermissionReadBookingAny"] = "ROLE_PERMISSION_READ_BOOKING_ANY";
    RolePermission["RolePermissionReadBookingOwn"] = "ROLE_PERMISSION_READ_BOOKING_OWN";
    RolePermission["RolePermissionReadLocation"] = "ROLE_PERMISSION_READ_LOCATION";
    RolePermission["RolePermissionReadOffer"] = "ROLE_PERMISSION_READ_OFFER";
    RolePermission["RolePermissionReadOrderAgency"] = "ROLE_PERMISSION_READ_ORDER_AGENCY";
    RolePermission["RolePermissionReadOrderAny"] = "ROLE_PERMISSION_READ_ORDER_ANY";
    RolePermission["RolePermissionReadOrderOwn"] = "ROLE_PERMISSION_READ_ORDER_OWN";
    RolePermission["RolePermissionReadTicketAgency"] = "ROLE_PERMISSION_READ_TICKET_AGENCY";
    RolePermission["RolePermissionReadTicketAny"] = "ROLE_PERMISSION_READ_TICKET_ANY";
    RolePermission["RolePermissionReadTicketOwn"] = "ROLE_PERMISSION_READ_TICKET_OWN";
    RolePermission["RolePermissionReadUserAgency"] = "ROLE_PERMISSION_READ_USER_AGENCY";
    RolePermission["RolePermissionReadUserAny"] = "ROLE_PERMISSION_READ_USER_ANY";
    RolePermission["RolePermissionReadUserOwn"] = "ROLE_PERMISSION_READ_USER_OWN";
    RolePermission["RolePermissionUnspecified"] = "ROLE_PERMISSION_UNSPECIFIED";
    RolePermission["RolePermissionUpdateAgency"] = "ROLE_PERMISSION_UPDATE_AGENCY";
    RolePermission["RolePermissionUpdateAirline"] = "ROLE_PERMISSION_UPDATE_AIRLINE";
    RolePermission["RolePermissionUpdateAirlineRule"] = "ROLE_PERMISSION_UPDATE_AIRLINE_RULE";
    RolePermission["RolePermissionUpdateAny"] = "ROLE_PERMISSION_UPDATE_ANY";
    RolePermission["RolePermissionUpdateAvatarAgency"] = "ROLE_PERMISSION_UPDATE_AVATAR_AGENCY";
    RolePermission["RolePermissionUpdateAvatarAny"] = "ROLE_PERMISSION_UPDATE_AVATAR_ANY";
    RolePermission["RolePermissionUpdateAvatarOwn"] = "ROLE_PERMISSION_UPDATE_AVATAR_OWN";
    RolePermission["RolePermissionUpdateBookingAgency"] = "ROLE_PERMISSION_UPDATE_BOOKING_AGENCY";
    RolePermission["RolePermissionUpdateBookingAny"] = "ROLE_PERMISSION_UPDATE_BOOKING_ANY";
    RolePermission["RolePermissionUpdateBookingOwn"] = "ROLE_PERMISSION_UPDATE_BOOKING_OWN";
    RolePermission["RolePermissionUpdateLocation"] = "ROLE_PERMISSION_UPDATE_LOCATION";
    RolePermission["RolePermissionUpdateOffer"] = "ROLE_PERMISSION_UPDATE_OFFER";
    RolePermission["RolePermissionUpdateOrderAgency"] = "ROLE_PERMISSION_UPDATE_ORDER_AGENCY";
    RolePermission["RolePermissionUpdateOrderAny"] = "ROLE_PERMISSION_UPDATE_ORDER_ANY";
    RolePermission["RolePermissionUpdateOrderOwn"] = "ROLE_PERMISSION_UPDATE_ORDER_OWN";
    RolePermission["RolePermissionUpdateTicketAgency"] = "ROLE_PERMISSION_UPDATE_TICKET_AGENCY";
    RolePermission["RolePermissionUpdateTicketAny"] = "ROLE_PERMISSION_UPDATE_TICKET_ANY";
    RolePermission["RolePermissionUpdateTicketOwn"] = "ROLE_PERMISSION_UPDATE_TICKET_OWN";
    RolePermission["RolePermissionUpdateUserAgency"] = "ROLE_PERMISSION_UPDATE_USER_AGENCY";
    RolePermission["RolePermissionUpdateUserAny"] = "ROLE_PERMISSION_UPDATE_USER_ANY";
    RolePermission["RolePermissionUpdateUserOwn"] = "ROLE_PERMISSION_UPDATE_USER_OWN";
})(RolePermission || (RolePermission = {}));
/** The role type */
export var RoleType;
(function (RoleType) {
    RoleType["RoleTypeAdmin"] = "ROLE_TYPE_ADMIN";
    RoleType["RoleTypeAgencyAdmin"] = "ROLE_TYPE_AGENCY_ADMIN";
    RoleType["RoleTypeAgent"] = "ROLE_TYPE_AGENT";
    RoleType["RoleTypeUnspecified"] = "ROLE_TYPE_UNSPECIFIED";
})(RoleType || (RoleType = {}));
/** The selection Type UNSPECIFIED|USER|SYSTEM */
export var SelectionCriteria;
(function (SelectionCriteria) {
    SelectionCriteria["SelectionCriteriaSystem"] = "SELECTION_CRITERIA_SYSTEM";
    SelectionCriteria["SelectionCriteriaUnspecified"] = "SELECTION_CRITERIA_UNSPECIFIED";
    SelectionCriteria["SelectionCriteriaUser"] = "SELECTION_CRITERIA_USER";
})(SelectionCriteria || (SelectionCriteria = {}));
/** The ticket status UNSPECIFIED|OPEN|VOID */
export var TicketStatus;
(function (TicketStatus) {
    TicketStatus["TicketStatusOpen"] = "TICKET_STATUS_OPEN";
    TicketStatus["TicketStatusUnspecified"] = "TICKET_STATUS_UNSPECIFIED";
    TicketStatus["TicketStatusVoid"] = "TICKET_STATUS_VOID";
})(TicketStatus || (TicketStatus = {}));
/** The ticket type EMD|TICKET */
export var TicketType;
(function (TicketType) {
    TicketType["TicketTypeAncillary"] = "TICKET_TYPE_ANCILLARY";
    TicketType["TicketTypeEmd"] = "TICKET_TYPE_EMD";
    TicketType["TicketTypeSegment"] = "TICKET_TYPE_SEGMENT";
    TicketType["TicketTypeTicket"] = "TICKET_TYPE_TICKET";
    TicketType["TicketTypeUnspecified"] = "TICKET_TYPE_UNSPECIFIED";
})(TicketType || (TicketType = {}));
export var ConvertImageDocument = "\n    mutation ConvertImage($file: Upload!) {\n  convertImage(file: $file) {\n    uri\n    mime_type\n    filename\n  }\n}\n    ";
export var useConvertImageMutation = function (options) {
    return useMutation(['ConvertImage'], function (variables) {
        return fetcherWithGraphQLClient(ConvertImageDocument, variables)();
    }, options);
};
useConvertImageMutation.getKey = function () { return ['ConvertImage']; };
export var CreateAgencyCommissionModuleDocument = "\n    mutation CreateAgencyCommissionModule($values: AgencyCommissionModuleInput!) {\n  createAgencyCommissionModule(values: $values)\n}\n    ";
export var useCreateAgencyCommissionModuleMutation = function (options) {
    return useMutation(['CreateAgencyCommissionModule'], function (variables) {
        return fetcherWithGraphQLClient(CreateAgencyCommissionModuleDocument, variables)();
    }, options);
};
useCreateAgencyCommissionModuleMutation.getKey = function () { return [
    'CreateAgencyCommissionModule',
]; };
export var CreateAgencyConsolidFeeModuleDocument = "\n    mutation CreateAgencyConsolidFeeModule($values: AgencyConsolidFeeModuleInput!) {\n  createAgencyConsolidFeeModule(values: $values)\n}\n    ";
export var useCreateAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['CreateAgencyConsolidFeeModule'], function (variables) {
        return fetcherWithGraphQLClient(CreateAgencyConsolidFeeModuleDocument, variables)();
    }, options);
};
useCreateAgencyConsolidFeeModuleMutation.getKey = function () { return [
    'CreateAgencyConsolidFeeModule',
]; };
export var CreateAgencyDocument = "\n    mutation CreateAgency($values: AgencyInput!) {\n  createAgency(values: $values) {\n    id\n  }\n}\n    ";
export var useCreateAgencyMutation = function (options) {
    return useMutation(['CreateAgency'], function (variables) {
        return fetcherWithGraphQLClient(CreateAgencyDocument, variables)();
    }, options);
};
useCreateAgencyMutation.getKey = function () { return ['CreateAgency']; };
export var CreateAirlineRulesSettingDocument = "\n    mutation CreateAirlineRulesSetting($airlineRulesSetting: AirlineRulesSettingInput!) {\n  createAirlineRulesSetting(airline_rules_setting: $airlineRulesSetting) {\n    id\n  }\n}\n    ";
export var useCreateAirlineRulesSettingMutation = function (options) {
    return useMutation(['CreateAirlineRulesSetting'], function (variables) {
        return fetcherWithGraphQLClient(CreateAirlineRulesSettingDocument, variables)();
    }, options);
};
useCreateAirlineRulesSettingMutation.getKey = function () { return [
    'CreateAirlineRulesSetting',
]; };
export var CreateUserDocument = "\n    mutation CreateUser($user: UserInput!) {\n  createUser(user: $user)\n}\n    ";
export var useCreateUserMutation = function (options) {
    return useMutation(['CreateUser'], function (variables) {
        return fetcherWithGraphQLClient(CreateUserDocument, variables)();
    }, options);
};
useCreateUserMutation.getKey = function () { return ['CreateUser']; };
export var DeleteAgencyCommissionModuleDocument = "\n    mutation DeleteAgencyCommissionModule($deleteAgencyCommissionModuleId: Float!) {\n  deleteAgencyCommissionModule(id: $deleteAgencyCommissionModuleId)\n}\n    ";
export var useDeleteAgencyCommissionModuleMutation = function (options) {
    return useMutation(['DeleteAgencyCommissionModule'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAgencyCommissionModuleDocument, variables)();
    }, options);
};
useDeleteAgencyCommissionModuleMutation.getKey = function () { return [
    'DeleteAgencyCommissionModule',
]; };
export var DeleteAgencyConsolidFeeModuleDocument = "\n    mutation DeleteAgencyConsolidFeeModule($deleteAgencyConsolidFeeModuleId: Float!) {\n  deleteAgencyConsolidFeeModule(id: $deleteAgencyConsolidFeeModuleId)\n}\n    ";
export var useDeleteAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['DeleteAgencyConsolidFeeModule'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAgencyConsolidFeeModuleDocument, variables)();
    }, options);
};
useDeleteAgencyConsolidFeeModuleMutation.getKey = function () { return [
    'DeleteAgencyConsolidFeeModule',
]; };
export var DeleteAgencyLogoDocument = "\n    mutation DeleteAgencyLogo($deleteAgencyLogoId: Float!) {\n  deleteAgencyLogo(id: $deleteAgencyLogoId)\n}\n    ";
export var useDeleteAgencyLogoMutation = function (options) {
    return useMutation(['DeleteAgencyLogo'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAgencyLogoDocument, variables)();
    }, options);
};
useDeleteAgencyLogoMutation.getKey = function () { return ['DeleteAgencyLogo']; };
export var DeleteAgencyDocument = "\n    mutation DeleteAgency($deleteAgencyId2: Float!) {\n  deleteAgency(id: $deleteAgencyId2)\n}\n    ";
export var useDeleteAgencyMutation = function (options) {
    return useMutation(['DeleteAgency'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAgencyDocument, variables)();
    }, options);
};
useDeleteAgencyMutation.getKey = function () { return ['DeleteAgency']; };
export var DeleteAirlineRuleDocument = "\n    mutation DeleteAirlineRule($deleteAirlineRuleId: Float!) {\n  deleteAirlineRule(id: $deleteAirlineRuleId)\n}\n    ";
export var useDeleteAirlineRuleMutation = function (options) {
    return useMutation(['DeleteAirlineRule'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAirlineRuleDocument, variables)();
    }, options);
};
useDeleteAirlineRuleMutation.getKey = function () { return ['DeleteAirlineRule']; };
export var DeleteAirlineRulesSettingDocument = "\n    mutation DeleteAirlineRulesSetting($deleteAirlineRulesSettingId: Float!) {\n  deleteAirlineRulesSetting(id: $deleteAirlineRulesSettingId)\n}\n    ";
export var useDeleteAirlineRulesSettingMutation = function (options) {
    return useMutation(['DeleteAirlineRulesSetting'], function (variables) {
        return fetcherWithGraphQLClient(DeleteAirlineRulesSettingDocument, variables)();
    }, options);
};
useDeleteAirlineRulesSettingMutation.getKey = function () { return [
    'DeleteAirlineRulesSetting',
]; };
export var DeleteUserDocument = "\n    mutation DeleteUser($deleteUserId: Float!) {\n  deleteUser(id: $deleteUserId)\n}\n    ";
export var useDeleteUserMutation = function (options) {
    return useMutation(['DeleteUser'], function (variables) {
        return fetcherWithGraphQLClient(DeleteUserDocument, variables)();
    }, options);
};
useDeleteUserMutation.getKey = function () { return ['DeleteUser']; };
export var DisableAgencyDocument = "\n    mutation DisableAgency($disableAgencyId: Float!) {\n  disableAgency(id: $disableAgencyId)\n}\n    ";
export var useDisableAgencyMutation = function (options) {
    return useMutation(['DisableAgency'], function (variables) {
        return fetcherWithGraphQLClient(DisableAgencyDocument, variables)();
    }, options);
};
useDisableAgencyMutation.getKey = function () { return ['DisableAgency']; };
export var DisableUserDocument = "\n    mutation DisableUser($disableUserId: Float!) {\n  disableUser(id: $disableUserId)\n}\n    ";
export var useDisableUserMutation = function (options) {
    return useMutation(['DisableUser'], function (variables) {
        return fetcherWithGraphQLClient(DisableUserDocument, variables)();
    }, options);
};
useDisableUserMutation.getKey = function () { return ['DisableUser']; };
export var EnableAgencyDocument = "\n    mutation EnableAgency($enableAgencyId: Float!) {\n  enableAgency(id: $enableAgencyId)\n}\n    ";
export var useEnableAgencyMutation = function (options) {
    return useMutation(['EnableAgency'], function (variables) {
        return fetcherWithGraphQLClient(EnableAgencyDocument, variables)();
    }, options);
};
useEnableAgencyMutation.getKey = function () { return ['EnableAgency']; };
export var EnableUserDocument = "\n    mutation EnableUser($enableUserId: Float!) {\n  enableUser(id: $enableUserId)\n}\n    ";
export var useEnableUserMutation = function (options) {
    return useMutation(['EnableUser'], function (variables) {
        return fetcherWithGraphQLClient(EnableUserDocument, variables)();
    }, options);
};
useEnableUserMutation.getKey = function () { return ['EnableUser']; };
export var LoggedInDocument = "\n    mutation LoggedIn {\n  loggedIn {\n    id\n    username\n    email\n    first_name\n    last_name\n    phone_number\n    permissions\n    roles\n    avatar {\n      small_uri\n      medium_uri\n      large_uri\n    }\n    agency_id\n    agency {\n      crm_company_id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useLoggedInMutation = function (options) {
    return useMutation(['LoggedIn'], function (variables) {
        return fetcherWithGraphQLClient(LoggedInDocument, variables)();
    }, options);
};
useLoggedInMutation.getKey = function () { return ['LoggedIn']; };
export var PutAgencyCommissionModuleDocument = "\n    mutation PutAgencyCommissionModule($values: AgencyCommissionModuleValues!) {\n  putAgencyCommissionModule(values: $values)\n}\n    ";
export var usePutAgencyCommissionModuleMutation = function (options) {
    return useMutation(['PutAgencyCommissionModule'], function (variables) {
        return fetcherWithGraphQLClient(PutAgencyCommissionModuleDocument, variables)();
    }, options);
};
usePutAgencyCommissionModuleMutation.getKey = function () { return [
    'PutAgencyCommissionModule',
]; };
export var PutAgencyConsolidFeeModuleDocument = "\n    mutation PutAgencyConsolidFeeModule($values: AgencyConsolidFeeModuleValues!) {\n  putAgencyConsolidFeeModule(values: $values)\n}\n    ";
export var usePutAgencyConsolidFeeModuleMutation = function (options) {
    return useMutation(['PutAgencyConsolidFeeModule'], function (variables) {
        return fetcherWithGraphQLClient(PutAgencyConsolidFeeModuleDocument, variables)();
    }, options);
};
usePutAgencyConsolidFeeModuleMutation.getKey = function () { return [
    'PutAgencyConsolidFeeModule',
]; };
export var UpdateAgencyCreditDocument = "\n    mutation UpdateAgencyCredit($values: AgencyCreditValues!, $updateAgencyCreditId: Float!) {\n  updateAgencyCredit(values: $values, id: $updateAgencyCreditId)\n}\n    ";
export var useUpdateAgencyCreditMutation = function (options) {
    return useMutation(['UpdateAgencyCredit'], function (variables) {
        return fetcherWithGraphQLClient(UpdateAgencyCreditDocument, variables)();
    }, options);
};
useUpdateAgencyCreditMutation.getKey = function () { return ['UpdateAgencyCredit']; };
export var UpdateAgencyDocument = "\n    mutation UpdateAgency($values: AgencyValues!, $updateAgencyId: Float!) {\n  updateAgency(values: $values, id: $updateAgencyId) {\n    id\n  }\n}\n    ";
export var useUpdateAgencyMutation = function (options) {
    return useMutation(['UpdateAgency'], function (variables) {
        return fetcherWithGraphQLClient(UpdateAgencyDocument, variables)();
    }, options);
};
useUpdateAgencyMutation.getKey = function () { return ['UpdateAgency']; };
export var UpdateUserDocument = "\n    mutation UpdateUser($values: UserValues!, $email: String!) {\n  updateUser(values: $values, email: $email) {\n    id\n  }\n}\n    ";
export var useUpdateUserMutation = function (options) {
    return useMutation(['UpdateUser'], function (variables) {
        return fetcherWithGraphQLClient(UpdateUserDocument, variables)();
    }, options);
};
useUpdateUserMutation.getKey = function () { return ['UpdateUser']; };
export var UploadAgencyLogoDocument = "\n    mutation UploadAgencyLogo($file: Upload!) {\n  uploadAgencyLogo(file: $file)\n}\n    ";
export var useUploadAgencyLogoMutation = function (options) {
    return useMutation(['UploadAgencyLogo'], function (variables) {
        return fetcherWithGraphQLClient(UploadAgencyLogoDocument, variables)();
    }, options);
};
useUploadAgencyLogoMutation.getKey = function () { return ['UploadAgencyLogo']; };
export var CreateAirlineRuleDocument = "\n    mutation CreateAirlineRule($airlineRule: AirlineRuleInput!, $airlineRulesSettingId: Float!) {\n  createAirlineRule(\n    airline_rule: $airlineRule\n    airline_rules_setting_id: $airlineRulesSettingId\n  ) {\n    id\n  }\n}\n    ";
export var useCreateAirlineRuleMutation = function (options) {
    return useMutation(['CreateAirlineRule'], function (variables) {
        return fetcherWithGraphQLClient(CreateAirlineRuleDocument, variables)();
    }, options);
};
useCreateAirlineRuleMutation.getKey = function () { return ['CreateAirlineRule']; };
export var GetAgenciesDocument = "\n    query GetAgencies {\n  getAgencies {\n    id\n    created_at\n    name\n    crm_company_id\n    enabled\n    last_activity\n    logo {\n      medium_uri\n    }\n    created_by_info {\n      first_name\n    }\n  }\n}\n    ";
export var useGetAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetAgencies'] : ['GetAgencies', variables], fetcherWithGraphQLClient(GetAgenciesDocument, variables), options);
};
useGetAgenciesQuery.getKey = function (variables) {
    return variables === undefined ? ['GetAgencies'] : ['GetAgencies', variables];
};
export var GetAgencyActivitiesDocument = "\n    query GetAgencyActivities($getAgencyId: Float!) {\n  getAgencyActivities: getAgency(id: $getAgencyId) {\n    activities {\n      activity_type\n      id\n      created_at\n      agency_id\n      user_agency_id\n      user_id\n      err\n      transaction\n      user_info {\n        first_name\n        last_name\n      }\n    }\n  }\n}\n    ";
export var useGetAgencyActivitiesQuery = function (variables, options) {
    return useQuery(['GetAgencyActivities', variables], fetcherWithGraphQLClient(GetAgencyActivitiesDocument, variables), options);
};
useGetAgencyActivitiesQuery.getKey = function (variables) { return ['GetAgencyActivities', variables]; };
export var AgencyBalanceDetailsDocument = "\n    query AgencyBalanceDetails($agencyId: Float!) {\n  getAgencyBalanceDetails(agency_id: $agencyId) {\n    tickets {\n      number\n      booking_id\n      created_at\n      user_info {\n        last_name\n        first_name\n      }\n      provider {\n        locator\n      }\n      price {\n        total\n      }\n      validating_carrier_info {\n        code\n        long_name\n      }\n    }\n    credit_amount\n    available_amount\n    used_amount\n  }\n}\n    ";
export var useAgencyBalanceDetailsQuery = function (variables, options) {
    return useQuery(['AgencyBalanceDetails', variables], fetcherWithGraphQLClient(AgencyBalanceDetailsDocument, variables), options);
};
useAgencyBalanceDetailsQuery.getKey = function (variables) { return ['AgencyBalanceDetails', variables]; };
export var GetAgencyBalanceDocument = "\n    query GetAgencyBalance($agencyId: Float!) {\n  getAgencyBalance(agency_id: $agencyId) {\n    credit_amount\n    used_amount\n    available_amount\n  }\n}\n    ";
export var useGetAgencyBalanceQuery = function (variables, options) {
    return useQuery(['GetAgencyBalance', variables], fetcherWithGraphQLClient(GetAgencyBalanceDocument, variables), options);
};
useGetAgencyBalanceQuery.getKey = function (variables) { return ['GetAgencyBalance', variables]; };
export var GetCommissionModuleAgenciesDocument = "\n    query GetCommissionModuleAgencies($getAgencyCommissionModuleId: Float) {\n  getAgencyCommissionModule(id: $getAgencyCommissionModuleId) {\n    agencies {\n      id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetCommissionModuleAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetCommissionModuleAgencies']
        : ['GetCommissionModuleAgencies', variables], fetcherWithGraphQLClient(GetCommissionModuleAgenciesDocument, variables), options);
};
useGetCommissionModuleAgenciesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetCommissionModuleAgencies']
        : ['GetCommissionModuleAgencies', variables];
};
export var GetAgencyCommissionModuleDocument = "\n    query GetAgencyCommissionModule($getAgencyCommissionModuleId: Float) {\n  getAgencyCommissionModule(id: $getAgencyCommissionModuleId) {\n    id\n    name\n    created_at\n    description\n    airlines_commissions {\n      id\n      airline_code\n      from_commission\n      to_commission\n      consolid_commission\n    }\n  }\n}\n    ";
export var useGetAgencyCommissionModuleQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyCommissionModule']
        : ['GetAgencyCommissionModule', variables], fetcherWithGraphQLClient(GetAgencyCommissionModuleDocument, variables), options);
};
useGetAgencyCommissionModuleQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyCommissionModule']
        : ['GetAgencyCommissionModule', variables];
};
export var GetAgencyCommissionModulesDocument = "\n    query GetAgencyCommissionModules {\n  getAgencyCommissionModules {\n    id\n    name\n    description\n  }\n}\n    ";
export var useGetAgencyCommissionModulesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyCommissionModules']
        : ['GetAgencyCommissionModules', variables], fetcherWithGraphQLClient(GetAgencyCommissionModulesDocument, variables), options);
};
useGetAgencyCommissionModulesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyCommissionModules']
        : ['GetAgencyCommissionModules', variables];
};
export var GetConsolidFeeModuleAgenciesDocument = "\n    query GetConsolidFeeModuleAgencies($getAgencyConsolidFeeModuleId: Float) {\n  getAgencyConsolidFeeModule(id: $getAgencyConsolidFeeModuleId) {\n    agencies {\n      id\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetConsolidFeeModuleAgenciesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetConsolidFeeModuleAgencies']
        : ['GetConsolidFeeModuleAgencies', variables], fetcherWithGraphQLClient(GetConsolidFeeModuleAgenciesDocument, variables), options);
};
useGetConsolidFeeModuleAgenciesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetConsolidFeeModuleAgencies']
        : ['GetConsolidFeeModuleAgencies', variables];
};
export var GetAgencyConsolidFeeModuleDocument = "\n    query GetAgencyConsolidFeeModule($getAgencyConsolidFeeModuleId: Float) {\n  getAgencyConsolidFeeModule(id: $getAgencyConsolidFeeModuleId) {\n    name\n    id\n    description\n    economy_consolid_fee\n    created_at\n    business_consolid_fee\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModuleQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyConsolidFeeModule']
        : ['GetAgencyConsolidFeeModule', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleDocument, variables), options);
};
useGetAgencyConsolidFeeModuleQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyConsolidFeeModule']
        : ['GetAgencyConsolidFeeModule', variables];
};
export var GetAgencyCreditDocument = "\n    query GetAgencyCredit($agencyId: Float!) {\n  getAgencyCredit(agency_id: $agencyId) {\n    credit_enabled\n    credit_amount\n    credit_period_type\n  }\n}\n    ";
export var useGetAgencyCreditQuery = function (variables, options) {
    return useQuery(['GetAgencyCredit', variables], fetcherWithGraphQLClient(GetAgencyCreditDocument, variables), options);
};
useGetAgencyCreditQuery.getKey = function (variables) { return [
    'GetAgencyCredit',
    variables,
]; };
export var GetAgencyDocument = "\n    query GetAgency($getAgencyId: Float!) {\n  getAgency(id: $getAgencyId) {\n    id\n    created_at\n    name\n    email\n    phone_number\n    default_business_fee\n    default_economy_fee\n    booking_contact_type\n    credit_enabled\n    credit_amount\n    credit_period_type\n    language\n    logo {\n      mime_type\n      small_uri\n      medium_uri\n      large_uri\n      original_uri\n    }\n    commission_module\n    consolid_fee_module\n    crm_company_id\n    enabled\n  }\n}\n    ";
export var useGetAgencyQuery = function (variables, options) {
    return useQuery(['GetAgency', variables], fetcherWithGraphQLClient(GetAgencyDocument, variables), options);
};
useGetAgencyQuery.getKey = function (variables) { return [
    'GetAgency',
    variables,
]; };
export var ListAirlineRulesSettingActivitiesDocument = "\n    query ListAirlineRulesSettingActivities($listAirlineRulesSettingActivitiesId: Float!) {\n  listAirlineRulesSettingActivities(id: $listAirlineRulesSettingActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          mime_type\n          small_uri\n          medium_uri\n          large_uri\n          original_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useListAirlineRulesSettingActivitiesQuery = function (variables, options) {
    return useQuery(['ListAirlineRulesSettingActivities', variables], fetcherWithGraphQLClient(ListAirlineRulesSettingActivitiesDocument, variables), options);
};
useListAirlineRulesSettingActivitiesQuery.getKey = function (variables) { return ['ListAirlineRulesSettingActivities', variables]; };
export var GetAirlineRuleActivitiesDocument = "\n    query getAirlineRuleActivities($listAirlineRuleActivitiesId: Float!) {\n  listAirlineRuleActivities(id: $listAirlineRuleActivitiesId) {\n    activities {\n      id\n      created_at\n      activity_type\n      user_id\n      user_agency_id\n      err\n      transaction\n      user_info {\n        id\n        first_name\n        last_name\n        email\n      }\n      agency_info {\n        id\n        name\n        logo {\n          small_uri\n        }\n      }\n    }\n  }\n}\n    ";
export var useGetAirlineRuleActivitiesQuery = function (variables, options) {
    return useQuery(['getAirlineRuleActivities', variables], fetcherWithGraphQLClient(GetAirlineRuleActivitiesDocument, variables), options);
};
useGetAirlineRuleActivitiesQuery.getKey = function (variables) { return ['getAirlineRuleActivities', variables]; };
export var GetAirlineRuleDocument = "\n    query GetAirlineRule($getAirlineRuleId: Float!) {\n  getAirlineRule(id: $getAirlineRuleId) {\n    airline_rule {\n      id\n      position\n      name\n      commission\n      endorsement\n      tourcode\n      created_at\n      deleted\n      valid_from\n      valid_to\n      conditions {\n        operator\n        type\n        values\n      }\n      commissions {\n        operator\n        type\n        values\n        percent\n      }\n    }\n  }\n}\n    ";
export var useGetAirlineRuleQuery = function (variables, options) {
    return useQuery(['GetAirlineRule', variables], fetcherWithGraphQLClient(GetAirlineRuleDocument, variables), options);
};
useGetAirlineRuleQuery.getKey = function (variables) { return [
    'GetAirlineRule',
    variables,
]; };
export var GetAirlineRulesSettingDocument = "\n    query GetAirlineRulesSetting($getAirlineRulesSettingId: Float!) {\n  getAirlineRulesSetting(id: $getAirlineRulesSettingId) {\n    airline_rules_setting {\n      id\n      airline\n      airline_info {\n        code\n        long_name\n        short_name\n      }\n      company_owner_id\n      pricing_rules {\n        tourcode\n        position\n        commission\n        name\n        id\n        last_update\n        is_expired\n        valid_from\n        valid_to\n        conditions {\n          type\n          operator\n          values\n        }\n        commissions {\n          type\n          operator\n          percent\n          values\n        }\n      }\n      created_at\n      deleted\n      enabled\n    }\n  }\n}\n    ";
export var useGetAirlineRulesSettingQuery = function (variables, options) {
    return useQuery(['GetAirlineRulesSetting', variables], fetcherWithGraphQLClient(GetAirlineRulesSettingDocument, variables), options);
};
useGetAirlineRulesSettingQuery.getKey = function (variables) { return ['GetAirlineRulesSetting', variables]; };
export var AirlinesDocument = "\n    query Airlines {\n  airlines {\n    short_name\n    long_name\n    code\n  }\n}\n    ";
export var useAirlinesQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['Airlines'] : ['Airlines', variables], fetcherWithGraphQLClient(AirlinesDocument, variables), options);
};
useAirlinesQuery.getKey = function (variables) {
    return variables === undefined ? ['Airlines'] : ['Airlines', variables];
};
export var GetAgencyCommissionModuleActivitiesDocument = "\n    query GetAgencyCommissionModuleActivities($getAgencyCommissionModuleId: Float) {\n  getCommissionModuleActivities: getAgencyCommissionModule(\n    id: $getAgencyCommissionModuleId\n  ) {\n    id\n    activities {\n      activity_type\n      id\n      created_at\n      agency_id\n      commission_module_id\n      user_id\n      err\n      transaction\n      agency_info {\n        name\n      }\n      user_info {\n        first_name\n        last_name\n        email\n        id\n      }\n    }\n  }\n}\n    ";
export var useGetAgencyCommissionModuleActivitiesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyCommissionModuleActivities']
        : ['GetAgencyCommissionModuleActivities', variables], fetcherWithGraphQLClient(GetAgencyCommissionModuleActivitiesDocument, variables), options);
};
useGetAgencyCommissionModuleActivitiesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyCommissionModuleActivities']
        : ['GetAgencyCommissionModuleActivities', variables];
};
export var GetAgencyConsolidFeeModuleActivitiesDocument = "\n    query GetAgencyConsolidFeeModuleActivities($getAgencyConsolidFeeModuleId: Float) {\n  getConsolidFeeModuleActivities: getAgencyConsolidFeeModule(\n    id: $getAgencyConsolidFeeModuleId\n  ) {\n    id\n    activities {\n      activity_type\n      agency_consolid_fee_module_id\n      agency_id\n      agency_info {\n        name\n      }\n      created_at\n      err\n      id\n      transaction\n      user_info {\n        first_name\n        last_name\n        email\n        id\n      }\n    }\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModuleActivitiesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyConsolidFeeModuleActivities']
        : ['GetAgencyConsolidFeeModuleActivities', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModuleActivitiesDocument, variables), options);
};
useGetAgencyConsolidFeeModuleActivitiesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyConsolidFeeModuleActivities']
        : ['GetAgencyConsolidFeeModuleActivities', variables];
};
export var GetAgencyConsolidFeeModulesDocument = "\n    query GetAgencyConsolidFeeModules {\n  getAgencyConsolidFeeModules {\n    id\n    name\n    description\n  }\n}\n    ";
export var useGetAgencyConsolidFeeModulesQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetAgencyConsolidFeeModules']
        : ['GetAgencyConsolidFeeModules', variables], fetcherWithGraphQLClient(GetAgencyConsolidFeeModulesDocument, variables), options);
};
useGetAgencyConsolidFeeModulesQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetAgencyConsolidFeeModules']
        : ['GetAgencyConsolidFeeModules', variables];
};
export var GetConditionsConfigDocument = "\n    query GetConditionsConfig {\n  getAirlineRuleComissionConditions {\n    commissions {\n      condition_type\n      operators {\n        operator_type\n        value_type\n      }\n    }\n    conditions {\n      condition_type\n      operators {\n        operator_type\n        value_type\n      }\n    }\n  }\n}\n    ";
export var useGetConditionsConfigQuery = function (variables, options) {
    return useQuery(variables === undefined
        ? ['GetConditionsConfig']
        : ['GetConditionsConfig', variables], fetcherWithGraphQLClient(GetConditionsConfigDocument, variables), options);
};
useGetConditionsConfigQuery.getKey = function (variables) {
    return variables === undefined
        ? ['GetConditionsConfig']
        : ['GetConditionsConfig', variables];
};
export var GetUserDocument = "\n    query GetUser($getUserId: Float) {\n  getUser(id: $getUserId) {\n    id\n    first_name\n    middle_name\n    last_name\n    email\n    phone_number\n    roles\n    disabled\n    language\n    avatar {\n      small_uri\n    }\n    agency {\n      name\n      logo {\n        small_uri\n      }\n    }\n  }\n}\n    ";
export var useGetUserQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetUser'] : ['GetUser', variables], fetcherWithGraphQLClient(GetUserDocument, variables), options);
};
useGetUserQuery.getKey = function (variables) {
    return variables === undefined ? ['GetUser'] : ['GetUser', variables];
};
export var GetUsersDocument = "\n    query GetUsers($filter: UserFilter) {\n  getUsers(filter: $filter) {\n    id\n    email\n    first_name\n    middle_name\n    last_name\n    last_activity\n    agency_id\n    disabled\n  }\n}\n    ";
export var useGetUsersQuery = function (variables, options) {
    return useQuery(variables === undefined ? ['GetUsers'] : ['GetUsers', variables], fetcherWithGraphQLClient(GetUsersDocument, variables), options);
};
useGetUsersQuery.getKey = function (variables) {
    return variables === undefined ? ['GetUsers'] : ['GetUsers', variables];
};
export var ListAirlineRulesSettingsDocument = "\n    query ListAirlineRulesSettings($airline: String!, $companyOwnerId: String!) {\n  listAirlineRulesSettings(airline: $airline, company_owner_id: $companyOwnerId) {\n    airline_rules_settings {\n      id\n      airline\n      company_owner_id\n      last_update\n    }\n  }\n}\n    ";
export var useListAirlineRulesSettingsQuery = function (variables, options) {
    return useQuery(['ListAirlineRulesSettings', variables], fetcherWithGraphQLClient(ListAirlineRulesSettingsDocument, variables), options);
};
useListAirlineRulesSettingsQuery.getKey = function (variables) { return ['ListAirlineRulesSettings', variables]; };
export var SaveAirlineRulesSettingDocument = "\n    mutation SaveAirlineRulesSetting($airlineRulesSetting: AirlineRulesSettingInput!) {\n  saveAirlineRulesSetting(airline_rules_setting: $airlineRulesSetting)\n}\n    ";
export var useSaveAirlineRulesSettingMutation = function (options) {
    return useMutation(['SaveAirlineRulesSetting'], function (variables) {
        return fetcherWithGraphQLClient(SaveAirlineRulesSettingDocument, variables)();
    }, options);
};
useSaveAirlineRulesSettingMutation.getKey = function () { return ['SaveAirlineRulesSetting']; };
export var SaveAirlineRulesDocument = "\n    mutation SaveAirlineRules($airlineRule: AirlineRuleInput!) {\n  saveAirlineRules(airline_rule: $airlineRule) {\n    id\n  }\n}\n    ";
export var useSaveAirlineRulesMutation = function (options) {
    return useMutation(['SaveAirlineRules'], function (variables) {
        return fetcherWithGraphQLClient(SaveAirlineRulesDocument, variables)();
    }, options);
};
useSaveAirlineRulesMutation.getKey = function () { return ['SaveAirlineRules']; };
export var SearchAirlinesDocument = "\n    query SearchAirlines($query: String!) {\n  searchAirlines(query: $query) {\n    code\n    short_name\n    long_name\n  }\n}\n    ";
export var useSearchAirlinesQuery = function (variables, options) {
    return useQuery(['SearchAirlines', variables], fetcherWithGraphQLClient(SearchAirlinesDocument, variables), options);
};
useSearchAirlinesQuery.getKey = function (variables) { return [
    'SearchAirlines',
    variables,
]; };
export var SearchCitiesDocument = "\n    query SearchCities($query: String!) {\n  searchCities(query: $query) {\n    code\n    name\n    country_code\n  }\n}\n    ";
export var useSearchCitiesQuery = function (variables, options) {
    return useQuery(['SearchCities', variables], fetcherWithGraphQLClient(SearchCitiesDocument, variables), options);
};
useSearchCitiesQuery.getKey = function (variables) { return [
    'SearchCities',
    variables,
]; };
export var SearchContinentsDocument = "\n    query SearchContinents($query: String!) {\n  searchContinents(query: $query) {\n    code\n    name\n  }\n}\n    ";
export var useSearchContinentsQuery = function (variables, options) {
    return useQuery(['SearchContinents', variables], fetcherWithGraphQLClient(SearchContinentsDocument, variables), options);
};
useSearchContinentsQuery.getKey = function (variables) { return ['SearchContinents', variables]; };
export var SearchCountriesDocument = "\n    query SearchCountries($query: String!) {\n  searchCountries(query: $query) {\n    code\n    name\n    continent_code\n  }\n}\n    ";
export var useSearchCountriesQuery = function (variables, options) {
    return useQuery(['SearchCountries', variables], fetcherWithGraphQLClient(SearchCountriesDocument, variables), options);
};
useSearchCountriesQuery.getKey = function (variables) { return [
    'SearchCountries',
    variables,
]; };
export var SearchLocationsDocument = "\n    query SearchLocations($query: String!) {\n  searchLocations(query: $query) {\n    code\n    name\n    city_name\n    country_name\n  }\n}\n    ";
export var useSearchLocationsQuery = function (variables, options) {
    return useQuery(['SearchLocations', variables], fetcherWithGraphQLClient(SearchLocationsDocument, variables), options);
};
useSearchLocationsQuery.getKey = function (variables) { return [
    'SearchLocations',
    variables,
]; };
export var SortAirlineRulesDocument = "\n    mutation SortAirlineRules($airlineRules: [AirlineRuleInput!]!) {\n  sortAirlineRules(airline_rules: $airlineRules)\n}\n    ";
export var useSortAirlineRulesMutation = function (options) {
    return useMutation(['SortAirlineRules'], function (variables) {
        return fetcherWithGraphQLClient(SortAirlineRulesDocument, variables)();
    }, options);
};
useSortAirlineRulesMutation.getKey = function () { return ['SortAirlineRules']; };
