import { useTranslation } from '@agentguru/i18n';
import { routes } from '../constants';
export function useNavBarRoutes() {
    var t = useTranslation().t;
    return [
        {
            key: 1,
            path: routes.goToAgencies,
            icon: 'ag-agency',
            label: t('routes.agencies'),
            disabled: false,
            matches: [routes.agencies],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 3,
            path: routes.goToCommissions,
            icon: 'ag-commission',
            label: t('routes.agency_commission'),
            disabled: false,
            matches: [routes.commissions],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 4,
            path: routes.goToServiceFee,
            icon: 'ag-payment',
            label: t('page.title.edit_service_fee'),
            disabled: false,
            matches: [routes.serviceFee],
            isCustomRoute: false,
            subRoutes: [],
        },
        {
            key: 2,
            path: routes.goToAirlineCommissions,
            icon: 'ag-airline-commissions',
            label: t('routes.airlines_commissions'),
            disabled: false,
            matches: [routes.airlinesCommissions],
            isCustomRoute: false,
            subRoutes: [],
        },
    ];
}
