var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// en
import agenciesEn from './languages/en_US/agencies.json';
import constantsEn from './languages/en_US/constants.json';
import dialogEn from './languages/en_US/dialog.json';
import errorsEn from './languages/en_US/errors.json';
import formEn from './languages/en_US/form.json';
import layoutEn from './languages/en_US/layout.json';
import routesEn from './languages/en_US/routes.json';
import serviceFeeEn from './languages/en_US/serviceFee.json';
import sharedEn from './languages/en_US/shared.json';
import validationEn from './languages/en_US/validation.json';
import buttonsEn from './languages/en_US/buttons.json';
import airlineCommissionsEn from './languages/en_US/airlineCommissions.json';
import ruleConditionEn from './languages/en_US/ruleCondition.json';
import pageEn from './languages/en_US/page.json';
// pt-BR
import agenciesPtBr from './languages/pt_BR/agencies.json';
import constantsPtBr from './languages/pt_BR/constants.json';
import dialogPtBr from './languages/pt_BR/dialog.json';
import errorsPtBr from './languages/pt_BR/errors.json';
import formPtBr from './languages/pt_BR/form.json';
import layoutPtBr from './languages/pt_BR/layout.json';
import routesPtBr from './languages/pt_BR/routes.json';
import serviceFeePtBr from './languages/pt_BR/serviceFee.json';
import sharedPtBr from './languages/pt_BR/shared.json';
import validationPtBr from './languages/pt_BR/validation.json';
import buttonsPtBr from './languages/pt_BR/buttons.json';
import airlineCommissionsPtBr from './languages/pt_BR/airlineCommissions.json';
import ruleConditionPtBr from './languages/pt_BR/ruleCondition.json';
import pagePtBr from './languages/pt_BR/page.json';
var messages = {
    en_US: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, agenciesEn), constantsEn), dialogEn), errorsEn), formEn), layoutEn), routesEn), serviceFeeEn), sharedEn), validationEn), buttonsEn), airlineCommissionsEn), ruleConditionEn), pageEn),
    },
    pt_BR: {
        translation: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, agenciesPtBr), constantsPtBr), dialogPtBr), errorsPtBr), formPtBr), layoutPtBr), routesPtBr), serviceFeePtBr), sharedPtBr), validationPtBr), buttonsPtBr), airlineCommissionsPtBr), ruleConditionPtBr), pagePtBr),
    },
};
export default messages;
